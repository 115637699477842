import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useIntl } from "gatsby-plugin-intl"
import DataCenterIcon from '../images/servers-icon.svg'
import Highlight from '../images/cloud-icon.svg'
import ProductPlan from "../components/ProductPlan"
import { FaInfoCircle, FaCheck, FaGlobeAmericas, FaWifi, FaWrench } from "react-icons/fa"

const CloudPage = () => {
  const intl = useIntl()
  const newItem = {
    'name': 'test cloud',
    'description': 'test desc',
    'price': 199.00,
    'quantity': 1,
    'type': 'cloud'
  }
  return (
    <Layout contentType="internal">
      <SEO title={intl.formatMessage({ id: "hosting.seo" })} />
      <section className="internal product-info product-cloud">
        <div className="container">
          <h1>Hosting</h1>
          <div className="row">
            <div className="col-12 col-md-5 offset-md-1 order-1">
              <div className="product-hero-description">
                <p className="text-medium">Está a planear usar um servidor dedicado para alojar a página web da sua empresa ou para um ambiente de desenvolvimentos sofisticado?</p>
                <p className="text-medium">A VEKS pode oferecer-lhe a melhor solução através dos nossos diversos pacotes de servidor e configuração personalizada.</p>
                <p className="text-medium">Além de fornecer servidores dedicados, fazemos a configuração, gestão, e monitorização dos servidores à sua medida.</p>
              </div>
            </div>
            <div className="col-8 offset-2 col-md-5 offset-md-1 order-0 order-md-2 cloud-highlight">
              <Highlight />
            </div>
          </div>
          <section className="product-plans">
            <h2>Planos</h2>
            <ProductPlan type="cloud-plan" btns="blue" item={newItem} />
            <ProductPlan type="cloud-plan" btns="blue" item={newItem} />
            <ProductPlan type="cloud-plan" btns="blue" item={newItem} />
            <div className="monitoring-services">
              <FaInfoCircle />
              <span>
                <p>Opção de adquirir <b>serviços de monitorização humana</b> durante 12 ou 24 horas para qualquer servidor.</p>
                <p>Verifique o nosso <b><a href="#">SLA - Acordo Nível de Serviço</a></b>.</p>
              </span>
              <p className="monitoring-services__contact"><b>PARA MAIS INFORMAÇÕES <a href="#">CONTACTE-NOS</a></b></p>
            </div>
          </section>
          <section className="product-advantages">
            <h3>As nossas vantagens</h3>
            <div className="row">
              <div className="col-12 col-md-5 offset-md-1">
                <ul>
                  <li><span></span>Hardware de marca líder do mercado;</li>
                  <li><span></span>Servidores dedicados para uso exclusivo do cliente;</li>
                  <li><span></span>Servidores escaláveis para as suas diversas necessidades;</li>
                  <li><span></span>Sistemas operativos up-to-date;</li>
                  <li><span></span>Infraestrutura garantida com máquinas reais, não virtualizadas;</li>
                  <li><span></span>Proteção contra ataques;</li>
                  <li><span></span>Servidor configurado à medida em menos de 48hrs;</li>
                </ul>
              </div>
              <div className="col-12 col-md-5">
                <ul>
                  <li><span></span>Alojamento na data centre mais ecológico da Europa;</li>
                  <li><span></span>Estabilidade e conhecimento líder do mercado através de anos de experiência;</li>
                  <li><span></span>Área de cliente fácil de utilizar para a monitorização dos servidores;</li>
                  <li><span></span>Equipa de suporte técnico disponível 24/7;</li>
                  <li><span></span>Equipa de programadores a sua disposição para qualquer intervenção;</li>
                  <li><span></span>Flexibilidade de alteração dos planos contratados.</li>
                </ul>
              </div>
            </div>
          </section>
          <section className="product-highlights">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3">
                <FaGlobeAmericas />
                <h3>Rede</h3>
                <ul>
                  <li><FaCheck />Tráfego ilimitado;</li>
                  <li><FaCheck />99.9% de disponibilidade de hardware e conexão garantida;</li>
                  <li><FaCheck />Toda a rede está dimensionada de forma multiplamente redundante em topologia anular;</li>
                  <li><FaCheck />Rede de transmissão ótica capaz de atingir capacidades de transmissão de vários Terabit por segundo.</li>
                </ul>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <FaWifi />
                <h3>Conexão</h3>
                <ul>
                  <li><FaCheck />Portas deficadas de 1-Gbit/s;</li>
                  <li><FaCheck />Conexões com os IXPs (Internet Exchange Points) da Europa, Asia e América;</li>
                  <li><FaCheck />Conexão externa ao backbone de alta velocidade de 550Gbit/s;</li>
                  <li><FaCheck />Backbone com conexões diretas aos provedores mais importantes e aos maiores pontos de intercâmbio Europeus como o T-Com, Global Crossing, Level 3, e DE-CIX.</li>
                </ul>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <DataCenterIcon />
                <h3>Data Center</h3>
                <ul>
                  <li><FaCheck />O nosso data center Europeu, datadock, tem uma localização central na Europa, ao longo das rotas de fibra ótica principais;</li>
                  <li><FaCheck />Infraestrutura constituída pelos equipamentos ecológicos mais modernos e instalada com um sistema inovador de refrigeração.</li>
                </ul>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <FaWrench />
                <h3>Manutenção</h3>
                <ul>
                  <li><FaCheck />Backups diários e discos em RAID Mirror;</li>
                  <li><FaCheck />100GB em Filesystems para backups;</li>
                  <li><FaCheck />Manutenção e análise diária ou semanal;</li>
                  <li><FaCheck />Firewall e proteção contra ataques;</li>
                  <li><FaCheck />Relatório da performance dos servidores.</li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </section>
    </Layout>
  );
}

export default CloudPage